import { Typography, Grid, useMediaQuery } from '@material-ui/core';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import poster from '../Video/test.jpg';
import Info from './Info';

declare module '@material-ui/core/styles/createBreakpoints' {
    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;
        laptop: true;
    }
}

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            laptop: 300,
            sm: 500,
            md: 700,
            lg: 1024,
            xl: 1200
        }
    }
})

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundImage: `url(${poster})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",
        maxWidth: "100vw"
    },
    big: {
        minHeight: "175vh",
        maxWidth: "100vw"
    },
}));


const Test = () => {

    const classes = useStyles();
    const petit = useMediaQuery(theme.breakpoints.down('md'));
    const minus = useMediaQuery('(max-width:399.95px)');
    const mini = useMediaQuery('(max-width:499.95px)');
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const laptop = useMediaQuery('(max-width:599.95px)');


    return (
        <Grid style={{ minHeight: minus ? "265vh" : mini ? "240vh" : laptop ? "220vh" : sm ? "190vh" : petit ?  "165vh" : lg ? "175vh" : "165vh" }} >     
        
            <Grid className={classes.background}>

                {!petit &&
                    <>
                        <Grid container justifyContent='center' alignItems='center' alignContent='center' style={{ height: "80vh" }}>
                            <Grid item xs={12} >
                                <Typography variant="h1" component="div" gutterBottom style={{ color: "orange", fontFamily: "cursive", textAlign: "center" }}>MiDel  Elec</Typography>
                            </Grid>
                            <Grid style={{ height: "40vh" }}></Grid>
                        </Grid>
                    </>
                }
                {petit &&
                    <>
                        <br />
                        <Grid style={{ height: "30vh" }}></Grid>
                        <Grid container justifyContent='center' alignItems='center' alignContent='center' style={{ height: "50vh" }}>
                            <Grid item xs={12} >
                                <Typography variant="h1" component="div" gutterBottom style={{ color: "orange", fontFamily: "cursive", textAlign: "center" }}>MiDel  Elec</Typography>
                            </Grid>
                        </Grid>
                    </>
                }

                {/* <Grid container justifyContent='center' alignItems='center' alignContent='center' style={{ minHeight: "30vh" }}>
                    <Grid item xs={12} md={9} >
                        <Typography variant="body1" style={{ color: "orange", fontFamily: "cursive", textAlign: "center", fontSize: "1.5em" }}>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum vero asperiores veritatis quia minima autem officia eius culpa facilis quod quibusdam vitae, placeat ipsam eveniet debitis dicta quidem suscipit sapiente.
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum vero asperiores veritatis quia minima autem officia eius culpa facilis quod quibusdam vitae, placeat ipsam eveniet debitis dicta quidem suscipit sapiente.
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum vero asperiores veritatis quia minima autem officia eius culpa facilis quod quibusdam vitae, placeat ipsam eveniet debitis dicta quidem suscipit sapiente. 
                            </Typography>
                    </Grid>
                </Grid> */}
                {/* <video autoPlay loop muted style={{ zIndex: 'auto', position: "fixed", left: 0, top: 0, }} poster={poster}>
                    <source src={test} type='video/mp4' />
                </video> */}
                <Info />


            </Grid>

        </Grid>
    );
}

export default Test;
