import Content from './Content';

const App = () => {
  return (
    <div >      
      <Content />
    </div>

  );
}

export default App;
