import { Button, Container, Typography, Grid, Card, useMediaQuery, Checkbox, FormControlLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import poster from '../Video/test.jpg'
import axios from 'axios';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    titre: {
        marginTop: theme.spacing(8),
        color: "black",
    },
    container: {
        marginTop: theme.spacing(2),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: "#F8A055",
    },
    error: {
        backgroundColor: "#F8A055",
        color: "white",
        textAlign: "center"
    },
    success: {
        backgroundColor: "#65A603",
        color: "white",
        textAlign: "center"
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
    fond: {
        backgroundColor: "black",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",

    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    createAccount: {
        marginRight: theme.spacing(3),
        float: "right"
    },
    background: {
        backgroundImage: `url(${poster})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",

    },
}));


interface ContactForm {
    nom: string,
    prenom: string,
    email: string,
    telephone: string,
    message: string,
    checkBox: boolean
}


const Contact = () => {

    const classes = useStyles();
    const theme = useTheme();
    const petit = useMediaQuery(theme.breakpoints.down('sm'));

    const [erreur, setErreur] = useState(false)
    const [success, setSuccess] = useState(false)
    const [labelErreur, setLabelErreur] = useState("Check")

    const closeModalErreurCheck = () => {
        setErreur(false)
    }
    const closeModalSuccess = () => {
        setSuccess(false)
    }

    const modalErreurCheck =
        <Dialog
            open={erreur}
            onClose={closeModalErreurCheck}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle className={classes.error}>Une erreur a été détectée</DialogTitle>
            <DialogContent style={{ textAlign: "center" }}>
                {
                    labelErreur === "Check" &&
                    <DialogContentText>

                        Merci d'accepter les conditions d'utilisation pour que l'email de contact soit envoyé
                    </DialogContentText>

                }
                {
                    labelErreur === "Send" &&
                    <DialogContentText>

                        Une erreur esy survenue lors de l'envoie de votre email de contact !<br /> Merci de réessayer...
                    </DialogContentText>

                }


            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>

    const modalSuccess =
        <Dialog
            open={success}
            onClose={closeModalSuccess}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle className={classes.success}>Envoie réussie</DialogTitle>
            <DialogContent style={{ textAlign: "center" }}>
                Votre demande de contact a bien été prise en compte ! <br /> Vous allez recevoir un email récapitulatif concernant votre demande. <br /> Nous vous répondrons dans les meilleurs délais.
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>

    return (
        <div style={{ minHeight: '100vh' }} className={classes.container}>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                style={{ height: "5vh" }}
            ></Grid>
            <Grid container justifyContent='center' alignItems='center' alignContent='center' style={{ height: "20vh" }}>
                {
                    !petit &&
                    <Grid item xs={12} >
                        <Typography variant="h2" component="div" gutterBottom style={{ color: "orange", fontFamily: "cursive", textAlign: "center", zIndex: "1" }}>Nous contacter</Typography>
                    </Grid>
                }
                {
                    petit &&
                    <Grid item xs={12} >
                        <Typography variant="h3" component="div" gutterBottom style={{ color: "orange", fontFamily: "cursive", textAlign: "center", zIndex: "1" }}>Nous contacter</Typography>
                    </Grid>
                }

            </Grid>
            <Grid container justifyContent='center' alignItems='center' alignContent='center' >
                <Grid item xs={11} lg={9}>
                    <Card elevation={3} style={{ minHeight: "70vh" }}>
                        <Grid container justifyContent='center' alignItems='center' alignContent='center'>
                            <Grid item xs={12} >
                                <Formik
                                    initialValues={{
                                        nom: '',
                                        prenom: '',
                                        email: '',
                                        telephone: '',
                                        message: '',
                                        checkBox: false
                                    }}
                                    onSubmit={(values: ContactForm, actions) => {
                                        if (values.checkBox)
                                            axios.post("https://1xs4uhiid1.execute-api.eu-west-3.amazonaws.com/prod/contact", { "phoneNumber": values.telephone, "email": values.email, "description": values.message, "name": values.nom, "firstName": values.prenom }, { headers: { 'Content-Type': 'application/json', 'x-api-key': "75XZO4s35L9GGIkKPF44S8lrYIfpswy0abRb6gZR" } }).then(() => {
                                                actions.resetForm()
                                                setSuccess(true)
                                            }).catch(() => {
                                                setLabelErreur("Send")
                                                setErreur(true)
                                            })
                                        else {
                                            setLabelErreur("Check")
                                            setErreur(true)
                                        }
                                    }}
                                    validationSchema={Yup.object().shape({
                                        email: Yup.string()
                                            .email()
                                            .required('Entrez un email valide'),
                                        nom: Yup.string()
                                            .required(
                                                'Entrez votre nom'
                                            ),
                                        telephone: Yup.string()
                                            .required(
                                                'Entrez votre numéro de téléphone'
                                            ),
                                        prenom: Yup.string()
                                            .required(
                                                'Entrez votre prénom'
                                            ),
                                        message: Yup.string()
                                            .required(
                                                'Entrez le contenu de votre demande'
                                            )
                                    })}
                                >
                                    {(props: FormikProps<ContactForm>) => {
                                        const {
                                            values,
                                            touched,
                                            errors,
                                            handleBlur,
                                            handleChange
                                        } = props
                                        return (


                                            <Form className={classes.form} noValidate>
                                                <Container>
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        id="nom"
                                                        value={values.nom}
                                                        label="Nom"
                                                        name="nom"
                                                        autoFocus
                                                        helperText={
                                                            errors.nom && touched.nom
                                                                ? errors.nom
                                                                : ''
                                                        }
                                                        error={
                                                            errors.nom && touched.nom
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="prenom"
                                                        label="Prenom"
                                                        value={values.prenom}
                                                        id="prenom"
                                                        autoComplete="current-password"
                                                        helperText={
                                                            errors.prenom && touched.prenom
                                                                ? 'Entrez votre prénom'
                                                                : ''
                                                        }
                                                        error={
                                                            errors.prenom && touched.prenom
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="email"
                                                        label="Email"
                                                        value={values.email}
                                                        id="email"
                                                        autoComplete="current-password"
                                                        helperText={
                                                            errors.email && touched.email
                                                                ? 'Entrez votre email'
                                                                : ''
                                                        }
                                                        error={
                                                            errors.email && touched.email
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="telephone"
                                                        label="Téléphone"
                                                        value={values.telephone}
                                                        id="telephone"
                                                        autoComplete="current-password"
                                                        helperText={
                                                            errors.telephone && touched.telephone
                                                                ? 'Entrez votre numéro de téléphone'
                                                                : ''
                                                        }
                                                        error={
                                                            errors.telephone && touched.telephone
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                        name="message"
                                                        label="Votre message"
                                                        value={values.message}
                                                        id="message"
                                                        autoComplete="current-password"
                                                        helperText={
                                                            errors.message && touched.message
                                                                ? 'Entrez le contenu de votre demande'
                                                                : ''
                                                        }
                                                        error={
                                                            errors.message && touched.message
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    <FormControlLabel label="J'accepte que mes données soient enregistrées et conservées pour l'usage décrit dans les conditions d'utilisation" control={<Checkbox name="checkBox" checked={values.checkBox} onChange={handleChange} />}></FormControlLabel>
                                                </Container>
                                                <Container className={classes.container}>
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                    >
                                                        Envoyer
                                                    </Button>
                                                </Container>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Grid>
                            <h2 style={{ textAlign: "center", height: "100%" }}>Devis Gratuit</h2>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            {modalErreurCheck}
            {modalSuccess}
        </div >


    );
}

export default Contact;
