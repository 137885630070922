/* eslint-disable jsx-a11y/anchor-is-valid */
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TwitterIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/Facebook";
import google from "../Image/google.png";
import logo from "../Image/logo.jpg";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F8A055",
    paddingTop: "16px",
    overflowX: "hidden",
  },
  footerSections: {
    marginLeft: "10 16px",
  },
  subFooter: {
    backgroundColor: "rgba(0, 0, 0, 0.15)",
    padding: "8px 16px 8px 16px",
    marginTop: "8px",
  },
  footerText: {
    color: "#fff",
    fontSize: "18px",
    lineHeight: 1.5,
    textAlign: "center",
  },
  invertedBtnDark: {
    color: "#fff",
    backgroundColor: "transparent",
    border: "2px #fff solid",
    boxShadow: "none",
    margin: "8px",
  },
  white: {
    color: "#ffffff",
    textAlign: "center",
    textDecoration: "none",
  },
  white_top: {
    color: "#ffffff",
    textAlign: "center",
    marginBottom: 15,
  },
  flexContainer: {
    display: "flex",
  },
  sousTitre: {
    marginBottom: theme.spacing(4)
  },
  sous: {
    marginBottom: theme.spacing(1)
  },
  google: {
    backgroundImage: `url(${google})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
}));

// Création du footer du site

function Footer() {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        className={classes.footerText + " " + classes.footerSections}
      >         
        <Grid item xs={12} md={4}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={12}  className={classes.sousTitre}>
              <a className={classes.white_top} style={{ fontWeight: 400 }}>
                <b>Nous trouver</b>
              </a>
            </Grid>
            <Grid item xs={12} className={classes.sous}>
              <a
                href="https://www.instagram.com/midelelec/?igshid=YmMyMTA2M2Y="
                className={classes.white_top}
              >
                <TwitterIcon style={{ fontWeight: 400, color: "black" }} />
              </a>
            </Grid>
            <Grid item xs={12} className={classes.sous}>
              <a
                href="https://www.facebook.com/midelelec/"
                className={classes.white_top}
              >
                <YouTubeIcon style={{ fontWeight: 400, color: "black" }} />
              </a>
            </Grid>
            <Grid item xs={12} className={classes.sous}>
              <a
                href="https://www.google.com/maps/place/MiDel+Elec/@49.3495419,4.2222289,15z/data=!4m2!3m1!1s0x0:0x68826b86e838b95f?sa=X&ved=2ahUKEwjP2ZGU3P_3AhVM0RoKHWqZAnAQ_BJ6BAhIEAU"
                className={classes.white_top}
              >
                <Grid
                  container
                  justifyContent="center"
                  alignContent="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    className={classes.google}
                    style={{
                      maxWidth: "22px",
                      maxHeight: "22px",
                      minWidth: "22px",
                      minHeight: "22px",
                      color: "white",
                      textAlign: "center",
                    }}
                  ></Grid>
                </Grid>
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={12} className={classes.sousTitre}>
              <a className={classes.white_top} style={{ fontWeight: 400 }}>
                <b>A propose de nous</b>
              </a>
            </Grid>
            <Grid item xs={12} className={classes.sous}>
              Devis gratuit / Assurance décennalle{" "}
            </Grid>
            <Grid item xs={12} className={classes.sous}>
              4 Rue de l'Église Saint-Martin 51 110 Warmeriville{" "}
            </Grid>
            <Grid item xs={12} className={classes.sous}> 
            SARL au capital de 5000€ / SIRET : 91089348600019 APE : 4321A
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <img src={logo} alt="Carte de visite" style={{ height: "20vh" }} />
        </Grid>
      </Grid>
      <Grid className={classes.subFooter} item xs={12}>
        <Typography className={classes.white}>
          Copyright © {currentYear} MiDel Elec
        </Typography>
      </Grid>
    </div>
  );
}
export default Footer;
