import { Typography, Grid, Divider, useMediaQuery, Card } from '@material-ui/core';
// import { makeStyles} from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Circle, useMapEvents } from 'react-leaflet'
import { FacebookProvider, Page } from 'react-facebook';
// import InstagramEmbed from 'react-instagram-embed';
import { makeStyles, useTheme } from '@material-ui/core/styles';
/* eslint-disable react-hooks/exhaustive-deps */

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    titre: {
        marginTop: theme.spacing(8),
    },
    horaire: {
        marginTop: theme.spacing(8),
    },
    container: {
        marginTop: theme.spacing(5),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: "#F8A055",
    },
    error: {
        backgroundColor: "red",
        color: "white",
        textAlign: "center"
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
    fond: {
        backgroundColor: "white",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",

    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    createAccount: {
        marginRight: theme.spacing(3),
        float: "right"
    }
}));

function LocationMarker() {
    const [position, setPosition] = useState(null)
    useEffect(() => {
        map.locate()
    }, [])
    const map = useMapEvents({
        locationfound(e: any) {
            setPosition(e.latlng)
            map.flyTo(e.latlng, map.getZoom())
        },
    })

    return position === null ? null : (
        <Marker position={position}>
            <Popup>Vous êtes ici</Popup>
        </Marker>
    )
}


const PlanAcces = () => {
    const classes = useStyles();
    // var position = [51.505, -0.09];
    const theme = useTheme();
    const petit = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div style={{ minHeight: '140vh' }}>
            <Grid container justifyContent='center' alignItems='center' alignContent='center' >
                <Grid item xs={6} >
                    <Divider></Divider>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" alignContent='center'>
                <Grid container justifyContent='center' alignItems='center' alignContent='center' style={{ height: "15vh" }}>
                    {
                        !petit &&
                        <Grid item xs={12} >
                            <Typography variant="h2" component="div" gutterBottom style={{ color: "orange", fontFamily: "cursive", textAlign: "center", zIndex: "1" }}>Où nous trouver ? </Typography>
                        </Grid>
                    }
                    {
                        petit &&
                        <Grid item xs={12} >
                            <Typography variant="h3" component="div" gutterBottom style={{ color: "orange", fontFamily: "cursive", textAlign: "center", zIndex: "1" }}>Où nous trouver ? </Typography>
                        </Grid>
                    }
                </Grid>
                <Grid item xs={9}>
                    <MapContainer center={[49.3497385306304, 4.2221776828789555]} zoom={9} style={{ height: '60vh' }} scrollWheelZoom={false}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={[49.3497385306304, 4.2221776828789555]}>
                            <Popup>

                                <Grid container justifyContent='center' alignItems='center' alignContent='center'>
                                    <Grid item xs={12}>
                                        <h2 style={{ paddingTop: 5, marginTop: 5 }}>MiDel Elec</h2>
                                        <h4 style={{ paddingTop: 0, marginTop: 2, marginBottom: 0 }}>4 Rue de l'Église Saint-Martin</h4>
                                        <h4 style={{ paddingTop: 0, marginTop: 0, marginBottom: 0 }}>51 110 Warmeriville</h4>
                                    </Grid>

                                    <Grid item xs={12}>
                                        Horaires :  lundi au vendredi de 8h30 à 18h30
                                    </Grid>
                                    <Grid item xs={12}>
                                        Intervention dans un périmètre de 50km
                                    </Grid>
                                </Grid>
                            </Popup>
                        </Marker>
                        <Circle
                            center={[49.3497385306304, 4.2221776828789555]}
                            pathOptions={{ fillColor: 'blue' }}
                            radius={50 * 1000}
                        >

                        </Circle>
                        <LocationMarker />
                    </MapContainer>
                </Grid>
                <Grid item xs={12} className={classes.horaire} >
                    <Grid container justifyContent='center' alignItems='center' alignContent='center'>
                        <Grid item xs={11} sm={8} lg={6}>
                            <Card elevation={3} style={{ minHeight: "20vh" }}>
                                <Grid container justifyContent='center' alignItems='center' alignContent='center'>
                                    <Grid item xs={12}>
                                        <h2 style={{ textAlign: "center" }}>MiDel Elec</h2>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <h4 style={{ textAlign: "center", paddingTop: 0, marginTop: 2, marginBottom: 0 }}>4 Rue de l'Église Saint-Martin 51 110 Warmeriville</h4>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <h4 style={{ textAlign: "center", paddingTop: 0, marginTop: 4, marginBottom: 0 }}><i>Intervention dans un périmètre de 50 km</i></h4>

                                    </Grid>
                                    <Grid item xs={6} style={{ marginTop: 6, marginBottom: 6 }}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <h4 style={{ textAlign: "center", paddingTop: 0, marginTop: 10, marginBottom: 15 }}>06-51-73-73-70 / midelelec@gmail.com</h4>
                                    </Grid>

                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={12} className={classes.titre} >
                    <Grid container justifyContent="center" alignItems="center" alignContent='center'>

                        <FacebookProvider appId="5006678392755064" >
                            <Page href="https://m.facebook.com/midelelec/" tabs="timeline" />
                        </FacebookProvider>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' alignContent='center' style={{ minHeight: '5vh' }}>
            </Grid>
        </div >
    );
}

export default PlanAcces;
