import {
  Typography,
  Grid,
  Card,
  CardMedia,
  Divider,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import poster from '../Video/test.jpg'
import "aos/dist/aos.css";
import Footer from "./Footer";
import AppBar from "./AppBar";
import avantEpoye from "../Image/avantEpoye.jpg";
import apresEpoye from "../Image/apresEpoye.jpg";
import extracteur from "../Image/extracteur.jpg";
import creationVMCBraine from "../Image/creationVMCBraine.jpg";
import prise from "../Image/prise.jpg";
import tableauAvant from "../Image/tableauAvant.jpg";
import tableauApres from "../Image/tableauApres.jpg";
import remiseAuxNormes from "../Image/remiseAuxNormes.jpeg";
import remiseAuxNormes2 from "../Image/remiseAuxNormes2.jpeg";
import remiseAuxNormes3 from "../Image/remiseAuxNormes3.jpeg";
import renovationComplete from "../Image/renovationComplete.jpeg";
import renovationComplete1 from "../Image/renovationComplete1.jpeg";
import renovationComplete2 from "../Image/renovationComplete2.jpeg";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  titre: {
    marginTop: theme.spacing(8),
    color: "black",
  },
  container: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#F8A055",
  },
  error: {
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  fond: {
    backgroundColor: "black",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  createAccount: {
    marginRight: theme.spacing(3),
    float: "right",
  },
  info: {
    marginBottom: theme.spacing(3),
  },
  separate: {
    marginTop: theme.spacing(2),
  },
}));

const Prestation = () => {
  const classes = useStyles();
  // var position = [51.505, -0.09];

  const theme = useTheme();
  const petit = useMediaQuery(theme.breakpoints.down("md"));

  const mini = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <AppBar />
      <div style={{ minHeight: "100vh" }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          style={{ height: "5vh" }}
        ></Grid>

        {!petit && !mini && (
          <>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              style={{ height: "20vh" }}
            >
              <Grid item xs={12} lg={6}>
                <Typography
                  variant="h2"
                  component="div"
                  gutterBottom
                  style={{
                    color: "orange",
                    fontFamily: "cursive",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                  className={classes.container}
                >
                  Prestations
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Grid item xs={10} lg={12}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={10}>
                    <Card elevation={7} style={{ minHeight: "40vh" }}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        style={{ minHeight: "60vh" }}
                      >
                        <Grid item xs={3} className={classes.separate}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={creationVMCBraine}
                            style={{ minWidth: 100 }}
                            alt="VMC"
                          />
                          <h4 style={{ textAlign: "center" }}>VMC</h4>
                        </Grid>
                        <Grid item xs={1}>
                          {" "}
                        </Grid>
                        <Grid item xs={3} className={classes.separate}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={avantEpoye}
                            style={{ minWidth: 100 }}
                            alt="Paella dish"
                          />
                          <h4 style={{ textAlign: "center" }}>Avant Epoye</h4>
                        </Grid>
                        <Grid item xs={1}>
                          <Divider
                            style={{
                              backgroundColor: "black",
                              color: "black",
                              transform: "rotate(90deg)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.separate}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={apresEpoye}
                            style={{ minWidth: 100 }}
                            alt="Paella dish"
                          />
                          <h4 style={{ textAlign: "center" }}>Après Epoye</h4>
                        </Grid>
                        <Grid item xs={3}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={remiseAuxNormes}
                            style={{ minWidth: 100 }}
                            alt="Remise aux normes"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Remise aux normes
                          </h4>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={3}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={remiseAuxNormes2}
                            style={{ minWidth: 100 }}
                            alt="Remise aux normes 2"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Remise aux normes
                          </h4>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={3}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={remiseAuxNormes3}
                            style={{ minWidth: 100 }}
                            alt="Remise aux normes 3"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Remise aux normes
                          </h4>
                        </Grid>

                        <Grid item xs={3}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={renovationComplete}
                            style={{ minWidth: 100 }}
                            alt=" Rénovation complète"
                          />
                          <h4 style={{ textAlign: "center" }}>Rénovation complète</h4>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={3}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={renovationComplete1}
                            style={{ minWidth: 100 }}
                            alt=" Rénovation complète 2"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Rénovation complète
                          </h4>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={3}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={renovationComplete2}
                            style={{ minWidth: 100 }}
                            alt=" Rénovation complète 3"
                          />
                          <h4 style={{ textAlign: "center" }}>Rénovation complète</h4>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={3}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={extracteur}
                            style={{
                              minWidth: 100,
                              transform: "rotate(180deg)",
                            }}
                            alt="Extracteur d'air"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Extracteur d'air
                          </h4>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={3}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={prise}
                            style={{ minWidth: 100 }}
                            alt="Prise de recharge voiture"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Prise de recharge voiture
                          </h4>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={2}></Grid>

                        <Grid item xs={3}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={tableauAvant}
                            style={{ minWidth: 100 }}
                            alt="Paella dish"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Avant dépannage
                          </h4>
                        </Grid>
                        <Grid item xs={1}>
                          <Divider
                            style={{
                              backgroundColor: "black",
                              color: "black",
                              transform: "rotate(90deg)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={tableauApres}
                            style={{ minWidth: 100 }}
                            alt="Paella dish"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Après dépannage
                          </h4>
                        </Grid>
                        <Grid item xs={2}></Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={12} style={{ height: "5vh" }}></Grid>
                </Grid>
              </Grid>
              <Grid item lg={1}></Grid>
            </Grid>
          </>
        )}
        {petit && !mini && (
          <>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              style={{ height: "20vh" }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h2"
                  component="div"
                  gutterBottom
                  style={{
                    color: "orange",
                    fontFamily: "cursive",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                  className={classes.container}
                >
                  Prestations
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Grid item xs={11}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <Card elevation={7} style={{ minHeight: "40vh" }}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        style={{ minHeight: "60vh" }}
                      >
                        <Grid item md={5} className={classes.separate}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={creationVMCBraine}
                            style={{ minWidth: 100 }}
                            alt="VMC"
                          />
                          <h4 style={{ textAlign: "center" }}>VMC</h4>
                        </Grid>
                        <Grid item xs={1}>
                          {" "}
                        </Grid>
                        <Grid item xs={5} className={classes.separate}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={extracteur}
                            style={{
                              minWidth: 100,
                              transform: "rotate(180deg)",
                            }}
                            alt="Extracteur d'air"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Extracteur d'air
                          </h4>
                        </Grid>
                        <Grid item xs={5} >
                          <CardMedia
                            component="img"
                            height="300"
                            image={avantEpoye}
                            style={{ minWidth: 100 }}
                            alt="Paella dish"
                          />
                          <h4 style={{ textAlign: "center" }}>Avant Epoye</h4>
                        </Grid>
                        <Grid item xs={1}>
                          <Divider
                            style={{
                              backgroundColor: "black",
                              color: "black",
                              transform: "rotate(90deg)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={apresEpoye}
                            style={{ minWidth: 100 }}
                            alt="Paella dish"
                          />
                          <h4 style={{ textAlign: "center" }}>Après Epoye</h4>
                        </Grid>
                        <Grid item xs={5}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={remiseAuxNormes}
                            style={{ minWidth: 100 }}
                            alt="Remise aux normes"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Remise aux normes
                          </h4>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={remiseAuxNormes2}
                            style={{ minWidth: 100 }}
                            alt="Remise aux normes 2"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Remise aux normes
                          </h4>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={5}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={remiseAuxNormes3}
                            style={{ minWidth: 100 }}
                            alt="Remise aux normes 3"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Remise aux normes
                          </h4>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={5}>
                          <CardMedia
                            component="img"
                            height="300"
                            style={{ minWidth: 100}}

                            image={renovationComplete}
                            alt=" Rénovation complète"
                          />
                           <h4 style={{ textAlign: "center" }}>
                            Rénovation complète
                          </h4>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={renovationComplete1}
                            style={{ minWidth: 100 }}
                            alt=" Rénovation complète 2"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Rénovation complète
                          </h4>
                        </Grid>
                        <Grid item xs={5}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={renovationComplete2}
                            style={{ minWidth: 100 }}
                            alt=" Rénovation complète 3"
                          />
                           <h4 style={{ textAlign: "center" }}>
                            Rénovation complète
                          </h4>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={prise}
                            style={{ minWidth: 100 }}
                            alt="Prise de recharge voiture"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Prise de recharge voiture
                          </h4>
                        </Grid>

                        <Grid item xs={5}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={tableauAvant}
                            style={{ minWidth: 100 }}
                            alt="Paella dish"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Avant dépannage
                          </h4>
                        </Grid>
                        <Grid item xs={1}>
                          <Divider
                            style={{
                              backgroundColor: "black",
                              color: "black",
                              transform: "rotate(90deg)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={tableauApres}
                            style={{ minWidth: 100 }}
                            alt="Paella dish"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Après dépannage
                          </h4>
                        </Grid>
                        <Grid item xs={2}></Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={12} style={{ height: "5vh" }}></Grid>
                </Grid>
              </Grid>
              <Grid item lg={1}></Grid>
            </Grid>
          </>
        )}
        {mini && petit && (
          <>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              style={{ height: "20vh" }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h2"
                  component="div"
                  gutterBottom
                  style={{
                    color: "orange",
                    fontFamily: "cursive",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                  className={classes.container}
                >
                  Prestations
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Grid item sm={11} xs={12}>               
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                  >
                    <Grid item xs={12}>
                    <Card elevation={7} style={{ minHeight: "40vh" }}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        style={{ minHeight: "60vh" }}
                      >
                        <Grid item xs={5} className={classes.separate}>
                          <CardMedia
                            component="img"
                            height="200"
                            image={creationVMCBraine}
                            style={{ minWidth: 100 }}
                            alt="VMC"
                          />
                          <h4 style={{ textAlign: "center" }}>VMC</h4>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5} className={classes.separate}>
                          <CardMedia
                            component="img"
                            height="200"
                            image={extracteur}
                            style={{
                              minWidth: 100,
                              transform: "rotate(180deg)",
                            }}
                            alt="Extracteur d'air"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Extracteur d'air
                          </h4>
                        </Grid>
                        <Grid item xs={4} >
                          <CardMedia
                            component="img"
                            height="200"
                            image={avantEpoye}
                            style={{ minWidth: 100 }}
                            alt="Avant Epoye"
                          />
                          <h4 style={{ textAlign: "center" }}>Avant Epoye</h4>
                        </Grid>
                        <Grid item xs={1}>
                          <Divider
                            style={{
                              backgroundColor: "black",
                              color: "black",
                              transform: "rotate(90deg)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <CardMedia
                            component="img"
                            height="200"
                            image={apresEpoye}
                            style={{ minWidth: 100 }}
                            alt="Paella dish"
                          />
                          <h4 style={{ textAlign: "center" }}>Après Epoye</h4>
                        </Grid>
                        <Grid item xs={5}>
                          <CardMedia
                            component="img"
                            height="200"
                            image={remiseAuxNormes}
                            style={{ minWidth: 100 }}
                            alt="Remise aux normes"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Remise aux normes
                          </h4>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5}>
                          <CardMedia
                            component="img"
                            height="200"
                            image={remiseAuxNormes2}
                            style={{ minWidth: 100 }}
                            alt="Remise aux normes 2"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Remise aux normes
                          </h4>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={5}>
                          <CardMedia
                            component="img"
                            height="200"
                            image={remiseAuxNormes3}
                            style={{ minWidth: 100 }}
                            alt="Remise aux normes 3"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Remise aux normes
                          </h4>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5}>
                          <CardMedia
                            component="img"
                            height="200"
                            style={{ minWidth: 100}}

                            image={renovationComplete}
                            alt=" Rénovation complète"
                          />
                           <h4 style={{ textAlign: "center" }}>
                            Rénovation complète
                          </h4>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5}>
                          <CardMedia
                            component="img"
                            height="200"
                            image={renovationComplete1}
                            style={{ minWidth: 100 }}
                            alt=" Rénovation complète 2"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Rénovation complète
                          </h4>
                        </Grid>
                        <Grid item xs={5}>
                          <CardMedia
                            component="img"
                            height="200"
                            image={renovationComplete2}
                            style={{ minWidth: 100 }}
                            alt=" Rénovation complète 3"
                          />
                           <h4 style={{ textAlign: "center" }}>
                            Rénovation complète
                          </h4>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5}>
                          <CardMedia
                            component="img"
                            height="200"
                            image={prise}
                            style={{ minWidth: 100 }}
                            alt="Prise de recharge voiture"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Prise de recharge voiture
                          </h4>
                        </Grid>

                        <Grid item xs={5}>
                          <CardMedia
                            component="img"
                            height="200"
                            image={tableauAvant}
                            style={{ minWidth: 100 }}
                            alt="Avant dépannage"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Avant dépannage
                          </h4>
                        </Grid>
                        <Grid item xs={1}>
                          <Divider
                            style={{
                              backgroundColor: "black",
                              color: "black",
                              transform: "rotate(90deg)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <CardMedia
                            component="img"
                            height="200"
                            image={tableauApres}
                            style={{ minWidth: 100 }}
                            alt="Après dépannage"
                          />
                          <h4 style={{ textAlign: "center" }}>
                            Après dépannage
                          </h4>
                        </Grid>
                      </Grid>
                    </Card>
                    </Grid>
                    <Grid item xs={12} style={{ height: "5vh" }}></Grid>
                  </Grid>               
              </Grid>
              <Grid item lg={1}></Grid>
             
            </Grid>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Prestation;
