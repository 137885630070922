import {
  Typography,
  Grid,
  Card,
  useMediaQuery,
  Divider,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import poster from "../Video/test.jpg";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  titre: {
    marginTop: theme.spacing(8),
    color: "black",
  },
  container: {
    margin: theme.spacing(2),
  },
  double: {
    margin: theme.spacing(1),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#F8A055",
  },
  error: {
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
  },
  horaire: {
    marginTop: theme.spacing(8),
  },
  back1: {
    backgroundColor: "#77BDD9",
  },
  back2: {
    backgroundColor: "#77BDD9",
  },
  back3: {
    backgroundColor: "#77BDD9",
  },
  back4: {
    backgroundColor: "#77BDD9",
  },
  back5: {
    backgroundColor: "#77BDD9",
  },
  back6: {
    backgroundColor: "#77BDD9",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  fond: {
    backgroundColor: "black",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  decallage: {
    margin: theme.spacing(2),
  },
  createAccount: {
    marginRight: theme.spacing(3),
    float: "right",
  },
  background: {
    backgroundImage: `url(${poster})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  info: {
    margin: theme.spacing(3),
  },
}));

const Realisation = () => {
  const classes = useStyles();
  const theme = useTheme();
  const petit = useMediaQuery(theme.breakpoints.down("md"));
  const tpetit = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div style={{ minHeight: "100vh", maxWidth: "100%" }}>
      {/* <Grid container justifyContent='center' alignItems='center' alignContent='center' style={{ minHeight: '10vh' }}>
            </Grid> */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        style={{ height: "5vh" }}
      ></Grid>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={6}>
          <Divider />
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        style={{ height: "2vh" }}
      ></Grid>

      {!petit && !tpetit && (
        <>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            style={{ height: "15vh" }}
          >
            <Grid item xs={6}>
              <Typography
                variant="h2"
                component="div"
                gutterBottom
                style={{
                  color: "orange",
                  fontFamily: "cursive",
                  textAlign: "center",
                  zIndex: "1",
                }}
              >
                Ce que nous faisons{" "}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            style={{ minHeight: "20vh" }}
          >
            <div data-aos="fade-down" data-aos-anchor-placement="center-bottom">
              <Grid item xs={3} className={classes.double}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <Card
                      style={{
                        width: 200,
                        height: 200,
                      }}
                      className={classes.back1}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        style={{ height: 200, textAlign: "center" }}
                      >
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                          Installation électrique
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div data-aos="fade-down" data-aos-anchor-placement="center-bottom">
              <Grid item xs={2} className={classes.double}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <Card
                      style={{
                        width: 200,
                        height: 200,
                      }}
                      className={classes.back2}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        style={{ height: 200, textAlign: "center" }}
                      >
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                          Rénovation
                        </Grid>
                      </Grid>
                      <Grid></Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div data-aos="fade-down" data-aos-anchor-placement="center-bottom">
              <Grid item xs={2}>
                <Card
                  style={{
                    width: 200,
                    height: 200,
                  }}
                  className={classes.back3}
                >
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    style={{ height: 200, textAlign: "center" }}
                  >
                    <Grid item style={{ textAlign: "center" }}>
                      Dépannage
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </div>
            <div data-aos="fade-down" data-aos-anchor-placement="center-bottom">
              <Grid item xs={2} className={classes.double}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <Card
                      style={{
                        width: 200,
                        height: 200,
                      }}
                      className={classes.back4}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        style={{ height: 200, textAlign: "center" }}
                      >
                        <Grid item style={{ textAlign: "center" }}>
                          Remise aux normes
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            style={{ minHeight: "20vh" }}
          >
            <div data-aos="fade-down" data-aos-anchor-placement="center-bottom">
              <Grid item xs={2} className={classes.double}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <Card
                      style={{
                        width: 200,
                        height: 200,
                      }}
                      className={classes.back5}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        style={{ height: 200, textAlign: "center" }}
                      >
                        <Grid item style={{ textAlign: "center" }}>
                          Chauffage
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div data-aos="fade-down" data-aos-anchor-placement="center-bottom">
              <Grid item xs={2} className={classes.double}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <Card
                      style={{
                        width: 200,
                        height: 200,
                      }}
                      className={classes.back6}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        style={{ height: 200, textAlign: "center" }}
                      >
                        <Grid item style={{ textAlign: "center" }}>
                          VMC
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Grid item xs={12}>
              <h5 style={{ textAlign: "center", color: "black" }}>
                * Plus d'informations sur NOS PRESTATIONS
              </h5>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              style={{ minHeight: "2vh" }}
            ></Grid>
          </Grid>
        </>
      )}

      {petit && !tpetit && (
        <>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            style={{ height: "15vh" }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h2"
                component="div"
                gutterBottom
                style={{
                  color: "orange",
                  fontFamily: "cursive",
                  textAlign: "center",
                  zIndex: "1",
                }}
              >
                Ce que nous faisons{" "}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            style={{ minHeight: "3vh" }}
          ></Grid>

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            style={{ minHeight: "20vh" }}
          >
            <div data-aos="fade-down" data-aos-anchor-placement="center-bottom">
              <Grid item xs={4} className={classes.double}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <Card
                      style={{
                        width: 200,
                        height: 200,
                      }}
                      className={classes.back1}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        style={{ height: 200, textAlign: "center" }}
                      >
                        <Grid item style={{ textAlign: "center" }}>
                          Installation électrique
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div data-aos="fade-down" data-aos-anchor-placement="center-bottom">
              <Grid item xs={4} className={classes.double}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <Card
                      style={{
                        width: 200,
                        height: 200,
                      }}
                      className={classes.back2}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        style={{ height: 200, textAlign: "center" }}
                      >
                        <Grid item style={{ textAlign: "center" }}>
                          Rénovation
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div data-aos="fade-down" data-aos-anchor-placement="center-bottom">
              <Grid item xs={4} className={classes.double}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <Card
                      style={{
                        width: 200,
                        height: 200,
                      }}
                      className={classes.back3}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        style={{ height: 200, textAlign: "center" }}
                      >
                        <Grid item style={{ textAlign: "center" }}>
                          Dépannage
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            style={{ minHeight: "20vh" }}
          >
            <div data-aos="fade-down" data-aos-anchor-placement="center-bottom">
              <Grid item xs={4} className={classes.double}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <Card
                      style={{
                        width: 200,
                        height: 200,
                      }}
                      className={classes.back4}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        style={{ height: 200, textAlign: "center" }}
                      >
                        <Grid item style={{ textAlign: "center" }}>
                          Remise aux normes
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div data-aos="fade-down" data-aos-anchor-placement="center-bottom">
              <Grid item xs={4} className={classes.double}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <Card
                      style={{
                        width: 200,
                        height: 200,
                      }}
                      className={classes.back5}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        style={{ height: 200, textAlign: "center" }}
                      >
                        <Grid item style={{ textAlign: "center" }}>
                          Chauffage
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div data-aos="fade-down" data-aos-anchor-placement="center-bottom">
              <Grid item xs={4} className={classes.double}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <Card
                      style={{
                        width: 200,
                        height: 200,
                      }}
                      className={classes.back6}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        style={{ height: 200, textAlign: "center" }}
                      >
                        <Grid item style={{ textAlign: "center" }}>
                          VMC
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Grid item xs={12}>
              <h5 style={{ textAlign: "center", color: "black" }}>
                * Plus d'informations sur NOS PRESTATIONS
              </h5>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              style={{ minHeight: "1vh" }}
            ></Grid>
          </Grid>
        </>
      )}

      {petit && tpetit && (
        <>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            style={{ height: "15vh" }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h3"
                component="div"
                gutterBottom
                style={{
                  color: "orange",
                  fontFamily: "cursive",
                  textAlign: "center",
                  zIndex: "1",
                }}
              >
                Ce que nous faisons{" "}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            style={{ minHeight: "2vh" }}
          ></Grid>

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            style={{ minHeight: "20vh" }}
          >
            <div data-aos="fade-down" data-aos-anchor-placement="center-bottom">
              <Grid item xs={4} className={classes.double}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <Card
                      style={{
                        width: 200,
                        height: 200,
                      }}
                      className={classes.back1}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        style={{ height: 200, textAlign: "center" }}
                      >
                        <Grid item style={{ textAlign: "center" }}>
                          Installation électrique
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div data-aos="fade-down" data-aos-anchor-placement="center-bottom">
              <Grid item xs={4} className={classes.double}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <Card
                      style={{
                        width: 200,
                        height: 200,
                      }}
                      className={classes.back2}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        style={{ height: 200, textAlign: "center" }}
                      >
                        <Grid item style={{ textAlign: "center" }}>
                          Rénovation
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div data-aos="fade-down" data-aos-anchor-placement="center-bottom">
              <Grid item xs={4} className={classes.double}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <Card
                      style={{
                        width: 200,
                        height: 200,
                      }}
                      className={classes.back3}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        style={{ height: 200, textAlign: "center" }}
                      >
                        <Grid item style={{ textAlign: "center" }}>
                          Dépannage
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            style={{ minHeight: "15vh" }}
          >
            <div data-aos="fade-down" data-aos-anchor-placement="center-bottom">
              <Grid item xs={4} className={classes.double}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <Card
                      style={{
                        width: 200,
                        height: 200,
                      }}
                      className={classes.back4}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        style={{ height: 200, textAlign: "center" }}
                      >
                        <Grid item style={{ textAlign: "center" }}>
                          Remise aux normes
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div data-aos="fade-down" data-aos-anchor-placement="center-bottom">
              <Grid item xs={4} className={classes.double}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <Card
                      style={{
                        width: 200,
                        height: 200,
                      }}
                      className={classes.back5}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        style={{ height: 200, textAlign: "center" }}
                      >
                        <Grid item style={{ textAlign: "center" }}>
                          Chauffage
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div data-aos="fade-down" data-aos-anchor-placement="center-bottom">
              <Grid item xs={4} className={classes.double}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <Card
                      style={{
                        width: 200,
                        height: 200,
                      }}
                      className={classes.back6}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        style={{ height: 200, textAlign: "center" }}
                      >
                        <Grid item style={{ textAlign: "center" }}>
                          VMC
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Grid item xs={12}>
              <h5 style={{ textAlign: "center", color: "black" }}>
                * Plus d'informations sur NOS PRESTATIONS
              </h5>
            </Grid>

            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              style={{ minHeight: "2vh" }}
            ></Grid>
          </Grid>
        </>
      )}

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        style={{ minHeight: "2vh" }}
      ></Grid>
    </div>
  );
};

export default Realisation;
