import { AppBar, Container, Toolbar, Typography, Box, IconButton, Menu, MenuItem, Button, Grid, Divider, useMediaQuery } from '@material-ui/core';
import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { PATHS } from '../routes';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../Image/logo2.png'
const pages = ['Home', 'Nos prestations', 'Contact'];


const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const theme = useTheme()
  const history = useHistory()
  const petit = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  return (
    <AppBar style={{ background: '#F8A055' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {
            !petit &&
            <><img src={logo} alt="logo MiDel Elec" style={{ height: "3vh" }}></img></>
          }

          {
            petit &&
            <Box sx={{ flexGrow: 0 }}>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={() => {
                    if (page === "Nos prestations") {
                      history.push(PATHS.PRESTATION)
                    }
                    else if (page === "Home") {
                      history.push(PATHS.HOME)
                    }
                    else {
                      history.push(page)
                    }
                    handleCloseNavMenu()
                  }} >
                    <Typography >{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          }

          {petit &&
            <Grid container alignContent='center' alignItems='center' justifyContent='center'>
              <Grid item xs={6} md={2}>
                <Typography variant="h6">MiDel Elec</Typography>
              </Grid>
              <Grid item xs={1} sm={2} md={5}></Grid>
              <Grid item xs={5} sm={4} md={3} style={{ textAlign: "right" }}>
                <Typography variant="body1">06.51.73.73.70</Typography>
              </Grid>

            </Grid>}

          {!petit &&
            <>
              <Grid style={{ width: "1vw" }}></Grid>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <Grid item  >
                  <Divider orientation="vertical" style={{ backgroundColor: "white" }} />
                </Grid>
                <Grid item xs={10} >
                  {pages.map((page) => (
                    <Button
                      style={{ color: "white" }}
                      key={page}
                      onClick={() => {
                        if (page === "Nos prestations") {
                          history.push(PATHS.PRESTATION)
                        }
                        else if (page === "Home") {
                          history.push(PATHS.HOME)
                        }
                        else {
                          history.push(page)
                        }
                      }}
                    >
                      {page}
                    </Button>
                  ))}
                </Grid> 
                {/* surlignage lettre midel elec */}
                <Grid item xs={3} style={{ textAlign: "right" }}>
                  <Typography variant="h6">06.51.73.73.70</Typography>
                </Grid>
              </Box>
            </>
          }

        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;