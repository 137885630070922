import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { PATHS } from './routes';

import Home from './Page/Home';
import Contacte from './Page/Contacte';
import Prestation from './Page/Prestation';
import Page404 from './Page/404'

const Content: React.FC<{}> = () => {

    return (
        <Switch>
            <Route exact path={PATHS.HOME} component={Home} />
            <Route exact path={PATHS.CONTACT} component={Contacte} />
            <Route exact path={PATHS.PRESTATION} component={Prestation} />
            <Route component={Page404} />
        </Switch>
    );
};

export default Content;