//import test from "../Video/test.mp4";
import PlanAcces from './PlanAcces';
import Contact from './Contact';
import Footer from './Footer';
import { useEffect } from 'react';
import AppBar from './AppBar'

const Contacte = () => {

    useEffect(()=> {
        window.scrollTo({
            top: 0, 
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
          });
    }, [])

    return (
        <div style={{ minHeight: '100vh'}}>
            <AppBar/>          
            <Contact />
            <PlanAcces />
            <Footer />
            {/* <div className='test' style={{ height: "100%", width: "100%" }}>
                <div className="lines" style={{ height: "100%", width: "100%" }}>
                    <div className="line" style={{ rotate: "90deg" }}></div>

                    <div className="line"></div>
                    <div className="line"></div>

                </div>

            </div> */}


        </div >


    );
}

export default Contacte;
