import {
  Typography,
  Grid,
  Card,
  CardMedia,
  Divider,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import poster from '../Video/test.jpg'
import "aos/dist/aos.css";
import Nous from "../Image/nous.jpg";
import Camion from "../Image/camion.jpg";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  titre: {
    marginTop: theme.spacing(8),
    color: "black",
  },
  container: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#F8A055",
  },
  error: {
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  fond: {
    backgroundColor: "black",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  createAccount: {
    marginRight: theme.spacing(3),
    float: "right",
  },
  info: {
    margin: theme.spacing(2),
  },
}));

const Info = () => {
  const classes = useStyles();
  // var position = [51.505, -0.09];

  const theme = useTheme();
  const petit = useMediaQuery(theme.breakpoints.down("md"));
  const tpetit = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div style={{ height: "90vh" }}>
      {!petit && !tpetit && (
        <>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={8}>
              <Card elevation={7} style={{ minHeight: "30vh" }}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  style={{ minHeight: "20vh" }}
                >
                  <Grid item xs={12} className={classes.info}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "center" }}
                    >
                      <strong>MiDel Elec</strong> est une société spécialisée
                      dans l'électricité pour les particuliers.
                    </Typography>
                  </Grid>

                  <Grid item xs={12} className={classes.info}>
                    <Typography variant="body1" gutterBottom>
                      Il s'agit d'une entreprise familiale née de l'union de{" "}
                      <strong>Mi</strong>chel et <strong>Del</strong>phine.
                      <br />
                      <Grid item xs={12} style={{ height: "2vh" }}></Grid>
                      <strong>Michel</strong>, votre électricien, présent sur
                      tous vos chantiers, vous apporte tous les conseils
                      techniques, c'est le référant est garant de la réalisation
                      de vos travaux. <br />
                      <strong>Delphine</strong>, votre gestionnaire
                      administrative, vous accompagne du devis à la facturation
                      et assiste Michel sur les chantiers.
                      <br />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.info}>
                    <Typography variant="body1" gutterBottom>
                      Choisir <strong>MiDel Elec</strong>, c'est le choix :
                      <ul>
                        <li>
                          {" "}
                          Du professionnalisme : travaux électriques suivant la
                          NFC15-100, conseils techniques en neuf et en
                          rénovation
                        </li>
                        <li>
                          {" "}
                          De la proximité : nous intervenons à Warmeriville et
                          alentours, Reims et Rethel{" "}
                        </li>
                        <li> D'un contact humain simple, rapide et efficace</li>
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} style={{ height: "4vh" }}></Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={1}></Grid>

            <Grid item xs={4}>
              <div
                data-aos="fade-down"
                data-aos-anchor-placement="center-bottom"
              >
                <Card
                  elevation={4}
                  style={{
                    display: "inline-block",
                    height: 350,
                    objectFit: "cover"
                  }}
                >
                  <CardMedia
                    component="img"
                    image={Camion}
                    height={350}
                    alt="green iguana"
                    width={200}
                  />
                </Card>
              </div>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
              <div
                data-aos="fade-down"
                data-aos-anchor-placement="center-bottom"
              >
                <Card
                  elevation={4}
                  style={{
                    display: "inline-block",
                    height: 350,
                    width: 400,
                    objectFit: "cover"
                  }}
                >
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    style={{ height: "30vh" }}
                  >
                    <Grid item xs={12} className={classes.container}>
                      <CardMedia
                        component="img"
                        image={Nous}
                        style={{ textAlign: "center" }}
                        alt="Notre équipe"
                        height={500}
                        width={500}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </div>
            </Grid>
          </Grid>
        </>
      )}

      {petit && !tpetit && (
        <>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={6}>
              <Divider></Divider>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={11}>
              <Card elevation={7} style={{ minHeight: "30vh" }}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  style={{ minHeight: "20vh" }}
                >
                  <Grid item xs={12} className={classes.info}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "center" }}
                    >
                      <strong>MiDel Elec</strong> est une société spécialisée
                      dans l'électricité pour les particuliers.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ height: "2vh" }}></Grid>
                  <Grid item xs={12} className={classes.info}>
                    <Typography variant="body1" gutterBottom>
                      Il s'agit d'une entreprise familiale née de l'union de{" "}
                      <strong>Mi</strong>chel et <strong>Del</strong>phine.
                      <br />
                      <Grid item xs={12} style={{ height: "1vh" }}></Grid>
                      <strong>Michel</strong>, votre électricien, présent sur
                      tous vos chantiers, vous apporte tous les conseils
                      techniques, c'est le référant est garant de la réalisation
                      de vos travaux. <br />
                      <strong>Delphine</strong>, votre gestionnaire
                      administrative, vous accompagne du devis à la facturation
                      et assiste Michel sur les chantiers.
                      <br />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ height: "1vh" }}></Grid>
                  <Grid item xs={12} className={classes.info}>
                    <Typography variant="body1" gutterBottom>
                      Choisir <strong>MiDel Elec</strong>, c'est le choix :
                      <ul>
                        <li>
                          {" "}
                          Du professionnalisme : travaux électriques suivant la
                          NFC15-100, conseils techniques en neuf et en
                          rénovation
                        </li>
                        <li>
                          {" "}
                          De la proximité : nous intervenons à Warmeriville et
                          alentours, Reims et Rethel{" "}
                        </li>
                        <li> D'un contact humain simple, rapide et efficace</li>
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} style={{ height: "2vh" }}></Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={4}>
              <div
                data-aos="fade-down"
                data-aos-anchor-placement="center-bottom"
              >
                <Card
                  elevation={4}
                  style={{
                    display: "inline-block",
                    height: 350,
                    objectFit: "cover"
                  }}
                >
                  <CardMedia
                    component="img"
                    image={Camion}
                    height={350}
                    alt="green iguana"
                    width={200}
                  />
                </Card>
              </div>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={4}>
              <div
                data-aos="fade-down"
                data-aos-anchor-placement="center-bottom"
              >
                <Card
                  elevation={4}
                  style={{
                    display: "inline-block",
                    height: 350,
                    width: 400,
                    objectFit: "cover"
                  }}
                >
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    style={{ height: "30vh" }}
                  >
                    <Grid item xs={12} className={classes.container}>
                      <CardMedia
                        component="img"
                        image={Nous}
                        style={{ textAlign: "center" }}
                        alt="Notre équipe"
                        height={500}
                        width={500}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </div>
            </Grid>
          </Grid>
        </>
      )}

      {petit && tpetit && (
        <>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={6}>
              <Divider></Divider>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={12}>
              <Card elevation={7} style={{ minHeight: "30vh" }}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  style={{ minHeight: "20vh" }}
                >
                  <Grid item xs={12} className={classes.info}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ textAlign: "center" }}
                    >
                      <strong>MiDel Elec</strong> est une société spécialisée
                      dans l'électricité pour les particuliers.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ height: "1vh" }}></Grid>
                  <Grid item xs={12} className={classes.info}>
                    <Typography variant="body1" gutterBottom>
                      Il s'agit d'une entreprise familiale née de l'union de{" "}
                      <strong>Mi</strong>chel et <strong>Del</strong>phine.
                      <br />
                      <Grid item xs={12} style={{ height: "4vh" }}></Grid>
                      <strong>Michel</strong>, votre électricien, présent sur
                      tous vos chantiers, vous apporte tous les conseils
                      techniques, c'est le référant est garant de la réalisation
                      de vos travaux. <br />
                      <strong>Delphine</strong>, votre gestionnaire
                      administrative, vous accompagne du devis à la facturation
                      et assiste Michel sur les chantiers.
                      <br />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.info}>
                    <Typography variant="body1" gutterBottom>
                      Choisir <strong>MiDel Elec</strong>, c'est le choix :
                      <ul>
                        <li>
                          {" "}
                          Du professionnalisme : travaux électriques suivant la
                          NFC15-100, conseils techniques en neuf et en
                          rénovation
                        </li>
                        <li>
                          {" "}
                          De la proximité : nous intervenons à Warmeriville et
                          alentours, Reims et Rethel{" "}
                        </li>
                        <li> D'un contact humain simple, rapide et efficace</li>
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} style={{ height: "2vh" }}></Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item sm={1}></Grid>
            <Grid item xs={12} sm={5}>
              <div
                data-aos="fade-down"
                data-aos-anchor-placement="center-bottom"
              >
                <Card
                  elevation={3}
                  style={{
                    alignItems: "center",
                    display: "inline-block",
                    height: 270,
                    width: "100%"
                  }}
                >

                  <CardMedia component="img" height={270}
                    width={400} image={Camion} alt="Camion" />
                </Card>
              </div>
            </Grid>
            <Grid item sm={1}></Grid>
            <Grid item xs={12} sm={4}>
              <div
                data-aos="fade-down"
                data-aos-anchor-placement="center-bottom"
              >
                <Card
                  elevation={4}
                  style={{
                    alignItems: "center",
                    display: "inline-block",
                    height: 270,
                    width: "100%",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={Nous}
                    style={{ textAlign: "center" }}
                    height={270}
                    alt="Delphine"
                  />
                </Card>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default Info;
